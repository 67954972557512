<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <b-form-group>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            to="/push-campaigns/generator"
          >
            Create Campaign <feather-icon icon="FileTextIcon" size="12" />
          </b-button>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 d-flex align-items-center"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(start_date)="data">
            {{ data.value }}
          </template>
          <template #cell(total_recipients)="data">
            <p class="text-center">
              {{ data.value }}
            </p>
          </template>
          <template #cell(push_url)="data">
              <b-badge
                variant="light-secondary"
                class="text-center"
              >
                {{ data.value }}
              </b-badge>
   
          </template>
          <template #cell(accounts)="data">
            <span v-if="data.value.length != 0">
              <b-badge
                v-for="value in data.value"
                :key="value"
                variant="light-secondary"
                class="mr-1"
              >
                {{ value }}
              </b-badge>
              <br />
            </span>
            <b-badge v-if="data.value.length == 0" variant="light-warning">
              none
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == true"
              pill
              variant="success"
              class="badge-glow"
            >
              running
            </b-badge>
            <b-badge
              v-if="data.item.status == false"
              pill
              variant="warning"
              class="badge-glow"
            >
              paused
            </b-badge>
          </template>

          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-success"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Run"
                v-if="data.item.status == false"
                @click="runCampaign(data.item.id)"
              >
                <feather-icon icon="PlayIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-warning"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Pause"
                v-if="data.item.status == true"
                @click="pauseCampaign(data.item.id)"
              >
                <feather-icon icon="PauseIcon" size="20" />
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Preview"
                style="color: #746af8 !important"
                @click="previewTemplateOfCampaign(data.item)"
              >
             <span class="text-myinfo">texr</span> -->
              <!-- <feather-icon icon="EyeIcon" size="20" />
              </b-button> -->

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Edit"
                @click="getIdEdited(data.item.id)"
              >
                <feather-icon icon="EditIcon" size="20" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                v-b-tooltip.hover
                title="Delete"
                @click="confirmDelete(data.item.id)"
              >
                <feather-icon icon="Trash2Icon" size="20" />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>

      <b-modal
        id="modal-danger"
        ok-variant="danger"
        ok-title="Confirm"
        modal-class="modal-danger"
        centered
        title="Deleting Campaign!"
        @ok="deletePushCampaign"
      >
        <b-card-text>
          Are you sure you want to delete this campaign?
        </b-card-text>
      </b-modal>
      <b-col cols="6">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-25"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BCardText,
  BImg,
  BBadge,
  BCard,
  BRow,
  BCol,
  VBTooltip,
  BButtonGroup,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

export default {
  components: {
    BTable,
    BAvatar,
    VBTooltip,
    BCard,
    BImg,
    BButtonGroup,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      path: this.$ImagesURL,
      _id: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      __order_status: { status: "Pending", variant: "primary" },
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "status",
          label: "Status",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "push_name",
          label: "name",
        },
        {
          key: "push_url",
          label: "package name",
        },
        {
          key: "accounts",
          label: "Related To",
        },
        {
          key: "total_recipients",
          label: "Recipients",
        },
        "Actions",
      ],
      items: [],
      idDeleted: null,
    };
  },
  mounted() {
    this.getPushCampaign();
  },
  methods: {
    getPushCampaign() {
      axios.get("/get-push-campaign").then(({ data }) => {
        
        const result = data.data.map((item) => {
          const indexChar = item.push_url.indexOf("&");
          
          if (indexChar === -1) {
            var url = item.push_url;
          } else {
            url = item.push_url.substr(0, indexChar);
          }
          
          const accountsArr = JSON.parse(item.accounts).map((account) => {
            const accountObj = data.accounts.find((a) => a.id === account.id);
            return accountObj ? accountObj.account_name : "";
          });
          return { ...item, push_url: url, accounts: accountsArr };
        });
        this.items = result;
      });
    },
    getIdEdited: (id) => {
      router.push({ name: "edit-push-campaign", params: { id } });
    },
    confirmDelete(id) {
      this.idDeleted = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deletePushCampaign() {
      
      axios
        .post("/delete-push-campaign", {
          id: this.idDeleted,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.getPushCampaign();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    runCampaign(id) {
      axios
        .post("/run-push-campaign", {
          id: id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "PlayIcon", "Done", data.message);
            this.getPushCampaign();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    pauseCampaign(id) {
      axios
        .post("/pause-push-campaign", {
          id: id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "PauseIcon", "Done", data.message);
            this.getPushCampaign();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status == "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch(console.error);
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
